export const baseUpdateFormConfig = {
  formItems: [
    {
      field: 'remoteIp',
      type: 'input',
      label: 'terminal.remote-ip'
    },
    {
      field: 'operator',
      type: 'input',
      label: 'general.operator'
    },
    {
      field: 'status',
      type: 'select',
      label: 'common.app-status',
      options: [],
      otherOptions: {}
    },
    {
      field: 'createdBy',
      type: 'input',
      label: 'general.creation-user'
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description'
    }
  ],
  validateRules: {}
}
