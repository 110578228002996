export const baseViewFormConfig = {
  formItems: [
    {
      field: 'remoteIp',
      type: 'input',
      label: 'terminal.remote-ip',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'operator',
      type: 'input',
      label: 'general.operator',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'status',
      type: 'select',
      label: 'common.app-status',
      options: [],
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'createdBy',
      type: 'input',
      label: 'general.creation-user',
      otherOptions: {
        disabled: true
      }
    },
    {
      field: 'description',
      type: 'input',
      label: 'general.description',
      otherOptions: {
        disabled: true
      }
    }
  ]
}
